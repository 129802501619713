import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import { nanoid } from "nanoid"
import ReactMarkdown from "react-markdown"

import { Section, Container } from "../../anti/grid/grid"
import { Button, ButtonGroup } from "../../anti/buttons/buttons"

export const AcfHeadlineBlock = graphql`
  fragment AcfHeadlingBlock on WordPress_AcfHeadlineBlock {
    name
    attributes {
      className
    }
    headline {
      bgColor
      title
      text
      buttons {
        text
        link {
          target
          url
        }
      }
    }
  }
`

export const AcfHeadline = ({ data }) => {
  const [id] = useState(nanoid)
  const headlineBlock = data.headline
  return (
    <Section
      className={`${headlineBlock.bgColor} ${
        headlineBlock.attributes && headlineBlock.attributes.className
          ? headlineBlock.attributes.className
          : ""
      }`}
    >
      <Container className="mw-lg text-md-center">
        <h2 className="h2">
          <ReactMarkdown source={headlineBlock.title} escapeHtml={false} />
        </h2>
        <ReactMarkdown source={headlineBlock.text} escapeHtml={false} />
        {headlineBlock.buttons && (
          <ButtonGroup btnMargin="mx-0 mx-md-3" stackedSmDown>
            {headlineBlock.buttons.map((item, i) => {
              return (
                <Button
                  variant="link"
                  link={item.link && item.link.url}
                  target={item.link && item.link.target}
                  key={`${id}${i}`}
                >
                  {item.text}
                </Button>
              )
            })}
          </ButtonGroup>
        )}
      </Container>
    </Section>
  )
}

export const AcfHeadlineWShortcode = ({ data, category, lang }) => {
  const [id] = useState(nanoid)
  const headlineBlock = data.headline
  return (
    <Section
      className={`${headlineBlock?.bgColor} ${
        headlineBlock.attributes && headlineBlock.attributes.className
          ? headlineBlock.attributes.className
          : ""
      }`}
    >
      <Container>
        <div className="row">
          <div className="col-md-6 col-12">
            <h2 className="h2">{headlineBlock.title}</h2>
            <ReactMarkdown source={headlineBlock.text} escapeHtml={false} />
            {headlineBlock.buttons && (
              <ButtonGroup btnMargin="mx-0 mx-md-3" stackedSmDown>
                {headlineBlock.buttons.map((item, i) => {
                  return (
                    <Button
                      variant="link"
                      link={item.link && item.link.url}
                      target={item.link && item.link.target}
                      key={`${id}${i}`}
                    >
                      {item.text}
                    </Button>
                  )
                })}
              </ButtonGroup>
            )}
          </div>
          <div className="col-md-6 col-12">
            {
              category &&
              category.children.nodes.map((item, i) => {
                    return (
                      <Link
                        to={`/facilities/${category.slug}/${item.slug}`}
                        className="facilities-sub-list"
                      >
                        <div className="facilities-sub-list-info">
                          <h4>{item.count ? item.count : 0}</h4>
                          <p className="text-muted font-weight-bold">
                            {lang == "EN" && item.name}
                            {lang == "ID" && item.translation != null && item.translation.name}
                            {lang == "ID" && item.translation == null && item.name}
                          </p>
                        </div>
                      </Link>
                    )
              })}
          </div>
        </div>
      </Container>
    </Section>
  )
}
