import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import ReactMarkdown from "react-markdown"

import { Section, Container } from "../../anti/grid/grid"
import { Card } from "../../anti/card/card"
import { Button } from "../../anti/buttons/buttons"

// export const Fragment_AcfTextWImgCol = graphql`
//   fragment AcfTextWImgColWoContainer on WordPress_AcfTextWImgColWoContainer {
//     name
//     attributes {
//       className
//     }
//     colTextWImage {
//       bgColor
//       title
//       text
//       img {
//         sourceUrl(size: LARGE)
//       }
//       buttons {
//         text
//         link {
//           target
//           url
//         }
//       }
//     }
//   }
// `

export const AcfTextWImgColWoContainer = ({ data, slug }) => {
  const colTextWImage = data.colTextWImageWoContainer
  return (
    <Section
      className={`pt-4 pb-4 text-w-image-w-container ${
        colTextWImage.bgColor
      } ${slug !== "awards" && "no-container"}`}
    >
      <Container className="container-parent">
        <div className="row row-5">
          <div
            className={`col-md-6 ${
              colTextWImage.order === "reverse"
                ? "order-md-last"
                : "order-md-first"
            } mb-sm-down-4`}
          >
            <Card
              img={colTextWImage.img && colTextWImage.img.sourceUrl}
              imgHeight="h-ratio-2-1"
            />
          </div>
          <div
            className={`col-md-6 ${
              colTextWImage.order === "reverse"
                ? "order-md-first"
                : "order-md-last"
            } d-flex flex-column justify-content-center order-md-first ${slug ===
              "awards" && "pd-md-up-none"}`}
          >
            <Container>
              <div
                className={`${
                  colTextWImage.order === "reverse" ? "px-md-6" : "pr-md-6"
                } ${colTextWImage.bgColor}`}
              >
                {colTextWImage.label !== null && (
                  <label>{colTextWImage.label}</label>
                )}
                <h2 className="h2">
                  <ReactMarkdown source={colTextWImage.title} escapeHtml={false} />
                </h2>
                <p className="lead">
                  <ReactMarkdown source={colTextWImage.text} escapeHtml={false} />
                </p>
                {colTextWImage.icon !== null && (
                  <img
                    src={colTextWImage.icon.sourceUrl}
                    className="img-fluid icon"
                  />
                )}
                {colTextWImage.buttons !== null && (
                  <Button
                    variant="link"
                    link={
                      colTextWImage.buttons[0].link &&
                      colTextWImage.buttons[0].link.url
                    }
                  >
                    {colTextWImage.buttons[0].text}
                  </Button>
                )}
              </div>
            </Container>
          </div>
        </div>
      </Container>
    </Section>
  )
}


export const AcfTextWImgColWoContainerAwards = ({ data, slug }) => {
  const colTextWImage = data.colTextWImageWoContainer
  return (
    <Section
      className={`pt-4 pb-4 text-w-image-w-container ${
        colTextWImage.bgColor
      } ${slug !== "awards" && "no-container"}`}
    >
      <Container className="container-parent">
        <div className="row row-5">
          <div
            className={`col-md-5 ${
              colTextWImage.order === "reverse"
                ? "order-md-last"
                : "order-md-first"
            } mb-sm-down-4`}
          >
            <Card
              img={colTextWImage.img && colTextWImage.img.sourceUrl}
              imgHeight="h-ratio-3-2"
            />
          </div>
          <div
            className={`col-md-7 ${
              colTextWImage.order === "reverse"
                ? "order-md-first"
                : "order-md-last"
            } d-flex flex-column justify-content-center order-md-first ${slug ===
              "awards" && "pd-md-up-none"}`}
          >
            <Container>
              <div
                className={`${
                  colTextWImage.order === "reverse" ? "px-md-6" : "pr-md-6"
                } ${colTextWImage.bgColor}`}
              >
                {colTextWImage.label !== null && (
                  <label>{colTextWImage.label}</label>
                )}
                <h2 className="h2">
                  <ReactMarkdown source={colTextWImage.title} escapeHtml={false} />
                </h2>
                <p className="lead">
                  <ReactMarkdown source={colTextWImage.text} escapeHtml={false} />
                </p>
                {colTextWImage.icon !== null && (
                  <img
                    src={colTextWImage.icon.sourceUrl}
                    className="img-fluid icon"
                  />
                )}
                {colTextWImage.buttons !== null && (
                  <Button
                    variant="link"
                    link={
                      colTextWImage.buttons[0].link &&
                      colTextWImage.buttons[0].link.url
                    }
                  >
                    {colTextWImage.buttons[0].text}
                  </Button>
                )}
              </div>
            </Container>
          </div>
        </div>
      </Container>
    </Section>    
  )
}
