import React, { useLayoutEffect, useState, useContext } from "react"
import { navigate } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { LangContext } from "../context/LangContext"

// import { AcfColHeadline } from "../components/gutenberg/acf-col-headline"
import { AcfCoverMain } from "../components/gutenberg/acf-cover-main"
import { AcfGallery } from "../components/gutenberg/acf-gallery"
import { AcfCoverContent } from "../components/gutenberg/acf-cover-content"
import { AcfTextWImgColWoContainer } from "../components/gutenberg/acf-text-w-image-wo-container"
import { Section, Container } from "../components/anti/grid/grid"
import { CoreGroup } from "../components/gutenberg/core-group"
import { CoreParagraph } from "../components/gutenberg/core-paragraph"
import { AcfHeadlineWShortcode } from "../components/gutenberg/acf-headline"
import { AcfColHeadline } from "../components/gutenberg/acf-col-headline"
import { Card } from "../components/anti/card/card"
import { Link } from "../components/anti/utils/utils"

import "@glidejs/glide/dist/css/glide.core.min.css"
import "@glidejs/glide/dist/css/glide.theme.min.css"

const OurPartnersDetailsPage = ({ pageContext, location }) => {
  const { lang, setLang } = useContext(LangContext)
  const [modal, setModal] = useState({ data: null, show: false })
  const { seo } = pageContext.langKey === "en"
    ? pageContext.data
    : pageContext.data.translation
    || pageContext.data
  const wp = lang === "EN"
    ? pageContext.data?.blocks
    : pageContext.data?.translation?.blocks
    || pageContext.data?.blocks

  const wpID = pageContext.data?.translation && pageContext.data.translation?.blocks
  const currentSlug = pageContext.data.slug || pageContext.slug
  const category =
    pageContext.categories &&
    pageContext.categories.find(x => x.slug === currentSlug)
  const colTextWImage = modal.data && modal.data.blocks[0].colTextWImage

  // Modify navbar style
  useLayoutEffect(() => {
    const navbar = document.querySelector(".navbar")
    navbar.classList.remove("transparent")
    navbar.classList.replace("dark", "light")
  }, [])

  const Shortcode = ({ data, lang }) => {
    if (data.attributes.text === "facility-list") {
      return (
        <Section className="facility">
          <Container>
          </Container>
        </Section>
      )
    } else {
      return null
    }
  }

  const SEODefaultImg = lang === "EN"
    ? pageContext?.data?.blocks[0]?.coverMain?.img?.mediaItemUrl
    : pageContext?.data?.translation?.blocks[0]?.coverMain?.img?.mediaItemUrl
    || pageContext?.data?.blocks[0]?.coverMain?.img?.mediaItemUrl

  return (
    <>
      <Layout>
        <SEO
          title={seo.title}
          desc={seo.metaDesc}
          fbImg={seo.opengraphImage?.sourceUrl || SEODefaultImg}
          fbTitle={seo.opengraphTitle}
          fbDesc={seo.opengraphDescription}
          url={location.href}
          twitterTitle={seo.twitterTitle}
          twitterDesc={seo.twitterDescription}
          twitterImg={seo.twitterImage?.sourceUrl || SEODefaultImg}
        />
        {
          lang == "EN" &&
          wp.map((block, i) => {
            switch (block.name) {
              case "acf/cover-main":
                return (
                  <AcfCoverMain
                    title={pageContext.data && pageContext.data.title}
                    data={block}
                    key={`${block.name}${i}`}
                    breadcrumb={location.pathname}
                  />
                )
              case "acf/headline":
                return (
                  <AcfHeadlineWShortcode
                    data={block}
                    category={category}
                    key={`${block.name}${i}`}
                    lang={lang}
                  />
                )
              case "acf/col-headline":
                return <AcfColHeadline data={block} key={`${block.name}${i}`} />
              case "acf/acfgallery":
                return (
                  <AcfGallery
                    className="py-0"
                    data={block}
                    key={`${block.name}${i}`}
                  />
                )
              case "acf/cover-content":
                return <AcfCoverContent data={block} key={`${block.name}${i}`} />
              case "acf/col-text-w-image-wo-container":
                return (
                  <AcfTextWImgColWoContainer
                    data={block}
                    key={`${block.name}${i}`}
                  />
                )
              case "core/paragraph":
                return (
                  <Section
                    className={`${block.attributes.backgroundColor}`}
                    key={`${block.name}${i}`}
                  >
                    <CoreParagraph data={block} />
                  </Section>
                )
              case "core/group":
                return <CoreGroup data={block} key={`${block.name}${i}`} />
              case "core/shortcode":
                return <Shortcode lang={lang} data={block} key={`${block.name}${i}`} />
              default:
                break
            }
          })
        }
        {
          lang == "ID" && wpID != undefined &&
          wpID.map((block, i) => {
            switch (block.name) {
              case "acf/cover-main":
                return (
                  <AcfCoverMain
                    title={pageContext.data.translation && pageContext.data.translation.title}
                    data={block}
                    key={`${block.name}${i}`}
                    breadcrumb={location.pathname}
                  />
                )
              case "acf/headline":
                return (
                  <AcfHeadlineWShortcode
                    data={block}
                    category={category}
                    key={`${block.name}${i}`}
                    lang={lang}
                  />
                )
              case "acf/col-headline":
                return <AcfColHeadline data={block} key={`${block.name}${i}`} />
              case "acf/acfgallery":
                return (
                  <AcfGallery
                    className="py-0"
                    data={block}
                    key={`${block.name}${i}`}
                  />
                )
              case "acf/cover-content":
                return <AcfCoverContent data={block} key={`${block.name}${i}`} />
              case "acf/col-text-w-image-wo-container":
                return (
                  <AcfTextWImgColWoContainer
                    data={block}
                    key={`${block.name}${i}`}
                  />
                )
              case "core/paragraph":
                return (
                  <Section
                    className={`${block.attributes.backgroundColor}`}
                    key={`${block.name}${i}`}
                  >
                    <CoreParagraph data={block} />
                  </Section>
                )
              case "core/group":
                return <CoreGroup data={block} key={`${block.name}${i}`} />
              case "core/shortcode":
                return <Shortcode lang={lang} data={block} key={`${block.name}${i}`} />
              default:
                break
            }
          })
        }
        {
          lang == "ID" && wpID == undefined &&
          wp.map((block, i) => {
            switch (block.name) {
              case "acf/cover-main":
                return (
                  <AcfCoverMain
                    title={pageContext.data && pageContext.data.title}
                    data={block}
                    key={`${block.name}${i}`}
                    breadcrumb={location.pathname}
                  />
                )
              case "acf/headline":
                return (
                  <AcfHeadlineWShortcode
                    data={block}
                    category={category}
                    key={`${block.name}${i}`}
                    lang={lang}
                  />
                )
              case "acf/col-headline":
                return <AcfColHeadline data={block} key={`${block.name}${i}`} />
              case "acf/acfgallery":
                return (
                  <AcfGallery
                    className="py-0"
                    data={block}
                    key={`${block.name}${i}`}
                  />
                )
              case "acf/cover-content":
                return <AcfCoverContent data={block} key={`${block.name}${i}`} />
              case "acf/col-text-w-image-wo-container":
                return (
                  <AcfTextWImgColWoContainer
                    data={block}
                    key={`${block.name}${i}`}
                  />
                )
              case "core/paragraph":
                return (
                  <Section
                    className={`${block.attributes.backgroundColor}`}
                    key={`${block.name}${i}`}
                  >
                    <CoreParagraph data={block} />
                  </Section>
                )
              case "core/group":
                return <CoreGroup data={block} key={`${block.name}${i}`} />
              case "core/shortcode":
                return <Shortcode lang={lang} data={block} key={`${block.name}${i}`} />
              default:
                break
            }
          })
        }
      </Layout>

      {/* mobile drawer */}
      <div
        className={`d-md-none d-block facilities-drawer-mobile ${modal.show ? "show" : ""
          }`}
      >
        <div className="drawer-body">
          <div className="drawer-close">
            <div className="stripe" />
          </div>
          {colTextWImage &&
            colTextWImage.variant &&
            colTextWImage.variant === "paragraph_only" ? (
            <Section>
              <Container className="mw-sm">
                <h2>{colTextWImage.title}</h2>
                <p>{colTextWImage.text}</p>
              </Container>
            </Section>
          ) : (
            colTextWImage && (
              <Section>
                <Container>
                  <div className="row row-5">
                    <div className="col-md-6 mb-sm-down-4">
                      <Card
                        img={colTextWImage.img && colTextWImage.img.sourceUrl}
                        imgHeight="h-ratio-2-1"
                      />
                    </div>
                    <div className="col-md-6">
                      <h2 className="mb-3">{colTextWImage.title}</h2>
                      <p className="lead">{colTextWImage.text}</p>
                      <div className="row">
                        {colTextWImage &&
                          colTextWImage.list &&
                          colTextWImage.list.length > 0 &&
                          colTextWImage.list.map((item, i) => {
                            return (
                              <React.Fragment>
                                <div className="col-1 d-flex align-items-center">
                                  <img
                                    className="facilities-list-icon"
                                    src={item.icon && item.icon.sourceUrl}
                                    alt="list item"
                                  />
                                </div>
                                <div className="col-11">
                                  {item.text && !item.links && (
                                    <p className="mb-2">{item.text}</p>
                                  )}
                                  {item.links && (
                                    <Link
                                      className="py-0"
                                      target={item.links.target}
                                      to={item.links.url}
                                    >
                                      {item.text}
                                    </Link>
                                  )}
                                </div>
                              </React.Fragment>
                            )
                          })}
                      </div>

                      {colTextWImage.button &&
                        colTextWImage.button.link &&
                        colTextWImage.button.link.target === "_blank" && (
                          <a
                            className="btn btn-secondary btn-block direction-link mt-4"
                            target="_blank"
                            rel="noopener noreferrer"
                            href={
                              colTextWImage.button &&
                              colTextWImage.button.link.url
                            }
                          >
                            {colTextWImage.button && colTextWImage.button.text}
                          </a>
                        )}
                      {colTextWImage.button &&
                        colTextWImage.button.link &&
                        colTextWImage.button.link.target === "" && (
                          <button
                            className="btn btn-secondary btn-block direction-link mt-4"
                            onClick={() =>
                              navigate(`/${colTextWImage.button.text}`)
                            }
                          >
                            {colTextWImage.button && colTextWImage.button.text}
                          </button>
                        )}
                    </div>
                  </div>
                </Container>
              </Section>
            )
          )}
        </div>
      </div>
    </>
  )
}

export default OurPartnersDetailsPage
