import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useLazyQuery } from "@apollo/react-hooks"
import ReactMarkdown from "react-markdown"

import { nanoid } from "nanoid"
import gql from "graphql-tag"

import { Button } from "../../anti/buttons/buttons"
import { CoverContent } from "../../anti/cover/cover"

const Project = gql`
  query Search($key: String) {
    projects(where: { search: $key }) {
      edges {
        node {
          id
          slug
          propertyTypes {
            edges {
              node {
                slug
              }
            }
          }
        }
      }
    }
  }
`

export const AcfCoverContentBlock = graphql`
  fragment AcfCoverContentBlock on WordPress_AcfCoverContentBlock {
    attributes {
      className
    }
    coverContent {
      img {
        sourceUrl
      }
      imgOverlay
      title
      text
      buttons {
        text
        link {
          target
          url
        }
      }
    }
  }
`

export const AcfCoverContent = ({ data }) => {
  const [id] = useState(nanoid)
  const coverContent = data.coverContent

  const [getResult, { data: projectReturn, loading }] = useLazyQuery(Project)

  const buttons = data.coverContent.buttons

  useEffect(() => {
    buttons.map((button, i) => {
      button.link &&
        button.link.url.split("/")[1] == "project" &&
        getResult({
          variables: {
            key: button.link.url
              .split("/")[2]
              .split("-")
              .join(" "),
          },
        })
    })
  }, [])

  const projectParent =
    projectReturn &&
    projectReturn.projects.edges[0].node.propertyTypes.edges[0].node.slug

  return (
    <CoverContent
      theme="dark"
      img={coverContent.img.sourceUrl}
      imgOverlay={coverContent.imgOverlay}
      className={`${
        coverContent.attributes ? coverContent.attributes.className : ""
      }`}
    >
      <h2 className="h2">
        <ReactMarkdown source={coverContent.title} escapeHtml={false} />
      </h2>
      <p>
        <ReactMarkdown source={coverContent.text} escapeHtml={false} />
      </p>
      {coverContent.buttons && (
        <div className="btn-group d-block">
          {coverContent.buttons.map((button, i) => {
            return (
              (button.link && (
                <Button
                  variant="link"
                  target={button.link && button.link.target}
                  link={
                    button.link && button.link.url.split("/")[1] == "project"
                      ? `/development/${projectParent}/${
                          button.link.url.split("/")[2]
                        }`
                      : button.link.url
                  }
                  key={`${id}${i}`}
                >
                  {button.text}
                </Button>
              )) || <div></div>
            )
          })}
        </div>
      )}
    </CoverContent>
  )
}
