import React from "react"
import ReactMarkdown from "react-markdown"

import { Button } from "../../anti/buttons/buttons"
import { Section, Container } from "../../anti/grid/grid"

export const AcfColHeadlineBlock = `
fragment AcfColHeadlineBlock on WordPress_AcfColHeadlineBlock {
    name
    attributes {
      className
    }
    colHeadline {
      bgColor
      title
      text
      additionalInfo {
        label
        value
      }
      buttons {
        text
        link {
          target
          url
        }
      }
      list {
        text
        icon {
          sourceUrl
        }
      }
    }
  }
`

export const AcfColHeadline = ({ data }) => {
  const colHeadline = data.colHeadline
  return (
    <Section
      className={`${colHeadline.bgColor || ""} ${data.attributes.className ||
        ""}`}
    >
      <Container>
        <div className="row">
          <div className="col-lg-5 col-xl-6">
            <h2 className="h2">{colHeadline.title}</h2>
          </div>
          <div className="col-lg-7 col-xl-6">
            <ReactMarkdown source={colHeadline.text} escapeHtml={false} />
            {colHeadline.list &&
              colHeadline.list.length > 0 &&
              !undefined &&
              colHeadline.list.map((item, i) => {
                return (
                  <div className="icon-text">
                    <img src={item.icon.sourceUrl} />
                    <p><ReactMarkdown
                        source={item.text}
                        escapeHtml={false}
                      />
                    </p>
                  </div>
                )
              })}
            {colHeadline.buttons &&
              colHeadline.buttons.map((button, i) => {
                return (
                  <Button
                    variant="secondary"
                    className="btn-block-down-md mt-4"
                    link={button.link && button.link.url}
                    key={`${i}`}
                  >
                    {button.text} <i className="fas fa-arrow-right ml-1"></i>
                  </Button>
                )
              })}
          </div>
        </div>
      </Container>
    </Section>
  )
}
