import React from "react"
import { graphql } from "gatsby"
import ReactMarkdown from "react-markdown"

import { CoreParagraph } from "../core-paragraph"
import { CoreHeading } from "../core-heading"
import { Container, Section } from "../../anti/grid/grid"

export const CoreGroupBlock = graphql`
  fragment CoreGroupBlock on WordPress_CoreGroupBlock {
    innerBlocks {
      name
      ...CoreParagraphBlock
      ...CoreHeadingBlock
    }
  }
`

export const CoreGroup = ({ data, className, paragraphClassname }) => {
  return (
    <Section
      className={`${
        data.attributes.backgroundColor !== null
          ? data.attributes.backgroundColor
          : ""
      } ${
        data.attributes.className !== null ? data.attributes.className : ""
      } ${className ? className : ""}`}
    >
      <Container className="mw-lg">
        {data &&
          data.innerBlocks.map((value, i) => {
            switch (value.name) {
              case "core/heading":
                return (
                  <CoreHeading
                    data={value}
                    key={`core/group-${value.name}${i}`}
                  />
                )
              case "core/paragraph":
                return (
                  <CoreParagraph
                    data={value}
                    className={`${
                      paragraphClassname ? paragraphClassname : ""
                    } pt-3`}
                    key={`core/group-${value.name}${i}`}
                  />
                )
              case "core/list":
                return (
                  <ReactMarkdown
                    source={value.attributes.values}
                    escapeHtml={false}
                    className="px-3 pt-3"
                    key={`core/group-${value.name}${i}`}
                  />
                )
            }
          })}
      </Container>
    </Section>
  )
}
